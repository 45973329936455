import React from 'react'
import './Gallery.css'
import EP1 from '../../assets/images/ep5 (2).jpeg'
import EP2 from '../../assets/images/ep5 (3).jpeg'
import EP3 from '../../assets/images/ep5 (4).jpeg'
import EP4 from '../../assets/images/ep5 (5).jpeg'
import EP5 from '../../assets/images/ep5.jpeg'
import CP1 from '../../assets/images/cp1.jpeg'
import CP2 from '../../assets/images/cp2.jpeg'
import CP3 from '../../assets/images/cp3.jpeg'
import CP4 from '../../assets/images/cp4.jpeg'
import SP1 from '../../assets/images/sp1.jpeg'
import SP2 from '../../assets/images/sp2.jpeg'
import SP3 from '../../assets/images/sp3.jpeg'
import SP4 from '../../assets/images/sp4.jpeg'
import PR1 from '../../assets/images/pr1.jpeg'
import PR2 from '../../assets/images/pr2.jpeg'
import PR3 from '../../assets/images/pr3.jpeg'
import PR4 from '../../assets/images/pr4.jpeg'
import PR5 from '../../assets/images/pr5.jpeg'
import PR6 from '../../assets/images/pr6.jpeg'
import D1 from '../../assets/images/d1.JPG'
import D2 from '../../assets/images/d2.jpg'
import D3 from '../../assets/images/d3.jpg'
import D4 from '../../assets/images/d4.JPG'
import D5 from '../../assets/images/d5.jpg'
import D6 from '../../assets/images/d6.jpg'
import D7 from '../../assets/images/d7.JPG'
import V1 from '../../assets/images/v1.jpg'
import V2 from '../../assets/images/v2.jpg'
import V3 from '../../assets/images/v3.jpg'
import V4 from '../../assets/images/v4.jpg'
import V5 from '../../assets/images/v5.jpg'
import V6 from '../../assets/images/v6.jpg'
import V7 from '../../assets/images/v7.jpg'

function Gallery() {
    return (
        <div className='gallery'>
            <h2>Custom Patch Style</h2>
            <p>Over the years, we’ve made custom patches of all kinds. We offer many of the most popular styles for your sew-on or iron-on patches. We’ve created custom patches of all types, from clubs to organizations to plane models to soccer patches and more.</p>
            <h3>Embroidered Patches</h3>
            <div className='ep-pics'>
                <img className='ep-img' src={EP1} height="200px" width="200px" />
                <img className='ep-img' src={EP2} height="200px" width="200px" />
                <img className='ep-img' src={EP3} height="200px" width="200px" />
                <img className='ep-img' src={EP4} height="200px" width="200px" />
                <img className='ep-img' src={EP5} height="200px" width="200px" />
                
            </div>
            <h3>Chenille Patches</h3>
            <div className='ep-pics'>
                <img className='ep-img' src={CP1} height="200px" width="200px" />
                <img className='ep-img' src={CP2} height="200px" width="200px" />
                <img className='ep-img' src={CP3} height="200px" width="200px" />
                <img className='ep-img' src={CP4} height="200px" width="200px" />
                
            </div>
            <h3>Sublimation Patches</h3>
            <div className='ep-pics'>
                <img className='ep-img' src={SP1} height="200px" width="200px" />
                <img className='ep-img' src={SP2} height="200px" width="200px" />
                <img className='ep-img' src={SP3} height="200px" width="200px" />
                <img className='ep-img' src={SP4} height="200px" width="200px" />
                
            </div>
            <h3>PVC Rubber Patches</h3>
            <div className='ep-pics'>
                <img className='ep-img' src={PR1} height="200px" width="200px" />
                <img className='ep-img' src={PR2} height="200px" width="200px" />
                <img className='ep-img' src={PR3} height="200px" width="200px" />
                <img className='ep-img' src={PR4} height="200px" width="200px" />
                <img className='ep-img' src={PR5} height="200px" width="200px" />
                <img className='ep-img' src={PR6} height="200px" width="200px" />
                
            </div>
            <h3>Digitizing Samples</h3>
            <div className='ep-pics'>
                <img className='ep-img' src={D1} height="200px" width="200px" />
                <img className='ep-img' src={D2} height="200px" width="200px" />
                <img className='ep-img' src={D3} height="200px" width="200px" />
                <img className='ep-img' src={D4} height="200px" width="200px" />
                <img className='ep-img' src={D5} height="200px" width="200px" />
                <img className='ep-img' src={D6} height="200px" width="200px" />
                <img className='ep-img' src={D7} height="200px" width="200px" />
                
            </div>
            <h3>Vector Art Samples</h3>
            <div className='ep-pics'>
                <img className='ep-img' src={V1} height="200px" width="200px" />
                <img className='ep-img' src={V2} height="200px" width="200px" />
                <img className='ep-img' src={V3} height="200px" width="200px" />
                <img className='ep-img' src={V4} height="200px" width="200px" />
                <img className='ep-img' src={V5} height="200px" width="200px" />
                {/* <img className='ep-img' src={V6} height="200px" width="200px" />
                <img className='ep-img' src={V7} height="200px" width="200px" /> */}
                
            </div>
        </div>
    )
}

export default Gallery