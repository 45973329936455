import React from 'react'
import './Footer.css'
import Logo from '../../assets/images/logo.jpg'
import { FaFacebook } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";

function Footer() {
    return (
        <div className='footer'>
            
                <div className='footer-about'>
                    <a href='https://leeembroidery.com/'><h3>Lee Embroidery</h3></a>
                    <a><img className='logo' src={Logo} height="200px" width="200px" /></a>
                </div>
                <div className='footer-contact'>
                    <h3>Address</h3>
                    <h5>House # A5, Block-D Saima <br/> Arabian Villas
                        Survey No 72 <br/> to 81 Deh Jam Chakro Tappo <br/> Surjani Town
                    </h5>
                </div>
                <div className='footer-social'>
                    <h3>Follow Us</h3>
                    <div className='footer-social--icons'>
                        <div>
                            <a href='https://www.facebook.com/RealLeeEmbroidery?mibextid=ZbWKwL' target="_blank"><FaFacebook className='icons' /></a>
                        </div>
                        <div>
                            <a href='https://www.instagram.com/leeembroidery/?igshid=MGNiNDI5ZTU%3D' target="_blank"><FaInstagram className='icons' /></a>
                        </div>
                    </div>
                </div>
                <div className='footer-contact'>
                    <h3>Contact Us</h3>
                    <h5>+1 (678) 881-6653</h5>
                    <h5>+92 (320) 2123427</h5>
                    <h5>order@leeembroidery.com</h5>
                    <h5>sales.leeembroidery@gmail.com</h5>
                </div>
            
        </div>
    )
}

export default Footer