import React from 'react'
import './Second.css'
import Shipping from '../../assets/images/icon_shipping.png'
import Quote from '../../assets/images/icon_quote.png'
import Fast from '../../assets/images/icon_fast.png'
import Sample from '../../assets/images/icon_sample.png'

function Second() {
    return (
        <div className='second-container'>

            <h2>Why Choose Lee Embroidery ?</h2>
            <p>At Lee Embroidery, we’ve been creating custom and embroidered patches since 2000. Our goal is to make patch ordering as easy as possible and we work hard to provide the best quality workmanship to all of our customers. We also offer several important benefits that other patch companies don’t offer. Need more help? Make sure to read our custom patches guide for more information. This will answer many of your questions and help you begin your patch ordering process. Whether you need soccer patches, business patches, military patches, or 1,000’s of other custom patch styles, we can help.</p>
            <div className='second-card'>
            </div>
            <div className='cart'>
                <div className='main-gallery'>
                    <div class="gallery">
                        <img src={Shipping} />
                        <h4>Free Shipping</h4>
                    </div>
                    <div class="gallery">
                        <img src={Quote} />
                        <h4>Free Quote</h4>
                    </div>
                    <div class="gallery">
                        <img src={Sample} />
                        <h4>Free Sample</h4>
                    </div>
                    <div class="gallery">
                        <img src={Fast} />
                        <h4>Fast Turnaround</h4>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Second