import React from 'react'
import "./Pricing.css"


function Pricing() {
  return (
    <div className='price'>
        <h1>Pricing</h1>
        <div className='pricing'>
        <div className='digitizing'>
            <h3>Embroidery Digitizing</h3>
            <ul>
                <li><b>Simple : </b> $8</li>
                <li><b>Medium : </b> $15</li>
                <li><b>Complex : </b>Price is depend on <br/> design</li>
            </ul>
            {/* <button type='submit'>Order now</button> */}
        </div>
        <div className='vector'>
            <h3>Vector Art</h3>
            <ul>
                <li><b>Simple : </b> $8</li>
                <li><b>Medium : </b> $15</li>
                <li><b>Complex : </b>Price is depend on <br/> design</li>
            </ul>
            {/* <button type='submit'>Order now</button> */}
        </div>
        </div>
    </div>
  )
}

export default Pricing