import './App.css';
import Home from './components/Home/Home';
import Banner from './components/Banner/Banner';
import First from './components/First/First';
import Second from './components/Second/Second';
import Popular from './components/Popular/Popular';
import Categories from './components/Categories/Categories';
import Gallery from './components/Gallery/Gallery';
import Us from './components/About/Us';
import Email from './components/Email/Email';
import Footer from './components/Footer/Footer';
import Contact from './components/Contact/Contact';
import Pricing from './components/Pricing/Pricing';
import Reviews from './components/Reviews/Reviews';

function App() {
  return (
    <div className="App">
      <Home />
      <Banner />
      <First />
      <Second />
      <Categories />
      <Gallery />
      {/* <Reviews /> */}
      <Pricing />
      <Us />
      <Contact />
      <Footer />
    </div>
  );
}

export default App;
