import React from 'react'
import './Categories.css'
import EP from "../../assets/images/ep5.jpeg"
import SP from "../../assets/images/sp1.jpeg"
import CP from "../../assets/images/cp3.jpeg"
import PR from "../../assets/images/pr5.jpeg"
import D from "../../assets/images/d4.JPG"
import VA from "../../assets/images/v3.jpg"

function Categories() {
    return (
        <div className='categories'>
            <h2>Custom Patches Categories</h2>
            <p>We offer a wide variety of custom made patches for your specific goals or needs. Whatever the purpose of your patch, one of our friendly staff members can help. We encourage you to find out why so many people trust the experts at Lee Embroidery.</p>
            <div className='patches'>
                <div className='s-patch'>
                    <img src={EP} height="150px" width="130px" />
                    <p>Embroidered Patches</p>
                </div>
                <div className='s-patch'>
                    <img src={SP} height="150px" width="130px" />
                    <p>Sublimation Patches</p>
                </div>
                <div className='s-patch'>
                    <img src={CP} height="150px" width="130px" />
                    <p>Chenille Patches</p>
                </div>
                <div className='s-patch'>
                    <img src={PR} height="150px" width="130px" />
                    <p>PVC Rubber Patches</p>
                </div>
                <div className='s-patch'>
                    <img src={D} height="150px" width="130px" />
                    <p>Digitizing</p>
                </div>
                <div className='s-patch'>
                    <img src={VA} height="150px" width="130px" />
                    <p>Vector Art</p>
                </div>
            </div>
        </div>
    )
}

export default Categories