import React from 'react'
import './Us.css'

function Us() {
  return (
    <div className='why-us'>
      <h2>Why choose Us ?</h2>
      <p>One of the biggest reasons people choose to work with our company is attention to detail. After you submit a free quote, we work directly with you to ensure that your custom patches are made exactly as you requested. That is why it is so important that we provide you with a real sample before producing the full order. Other patch companies simply send you a PDF document of a rendered patch and not an actual representation.</p>
      <p>We also pride ourselves on offering the best customer service. When you call our office, one of our friendly staff members will speak with you and help you through the ordering process. We can help you decide which patch style is right for you business, organization, sports team. Other benefits include free shipping in the United States, fast turnaround, and premium quality custom patches.</p>
      <div className='why-us1'>
        <h2>Order Your Custom Patches</h2>
        <p>Order Your Custom Patches
The first step is to fill out our free quote form and submit a copy of your artwork. Typically we will follow up within the hour. Next, you will get a written confirmation of your custom patch order. After your order is placed, we will create and email you a scan of your physical patch for approval. Once everything is approved, you will receive your shipment. We make the patch ordering process as simple as possible.</p>
        
      </div>
    </div>

  )
}

export default Us