import React from 'react'
import "./Banner.css"
import Video from '../../assets/images/video.mp4'
import { FaPlay } from 'react-icons/fa'
// import slide_image1 from "../../assets/images/1.jpg"
// import slide_image2 from "../../assets/images/12.jpeg"
// import slide_image3 from "../../assets/images/4.jpg"

function Banner() {
  return (
    <video className='video' controls width="100%"  height="50%" >
      <source src={Video} type=" video/mp4" onCanPlay={true}/>
    </video>
  )
}

export default Banner

{/* <video controls width="100%">
      <source src="/video-example.webm" type="video/webm" />
      <source src="/video-example.mp4" type="video/mp4"
      />
      Sorry, your browser doesn't support videos.
    </video> */}
