import React from 'react'
import './Popular.css'

function Popular() {
  return (
    <div className='popular'>
        <h4>view our most popular patches</h4>
        <div className='buttons'>
            <button>CUSOTM PATCHES</button>
            <button>EMBROIDERED PATCHES</button>
            <button>IRON ON PATCHES</button>
            <button>SEW ON PATCHES</button>
            <button>SEW ON PATCHES</button>
        </div>
    </div>
  )
}

export default Popular