import React from 'react'
import "./First.css"
import multi_patches from "../../assets/images/multi_patches.png"

function First() {
    return (
        <div className='container'>
            <div className='l-container'>
                <h1>Custom Patches,</h1>
                <h1>IRON-ON PATCHES, AND MORE</h1>
                <p>At Lee Embroidery, we create custom patches for any purpose, in any shape or size. We offer premium quality embroidered patches, iron-on patches, woven patches, and many other styles to meet all of your needs. <br/>
                    No matter what your custom patches are going to be used for, we’ll make sure to craft them with care. Whether you wear them or display them, you’ll love your custom made patches. We only use the finest quality materials and the best techniques. This means your custom patches will be durable and look great for years to come. You’ll never have to worry about them fading or fraying unlike other companies.</p>
            </div>
            <div className='r-container'>
                <img src={multi_patches} />
            </div>
        </div>
    )
}

export default First